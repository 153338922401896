import { styled } from '@linaria/react';

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: var(--radius-round);
  border: 1px solid var(--border-hairline);
  transition: box-shadow 200ms;
  cursor: pointer;
  background-color: var(--surface-bright);
  height: 100%;

  width: 20rem;
  flex-direction: column;

  body[data-mobile='true'] & {
    justify-content: flex-start;
    width: 100%;
    flex-direction: row;
  }

  &:hover {
    box-shadow: var(--elevation-bottom-2);

    > div:first-child:before {
      opacity: 1;
    }
  }
`;

export const CardTop = styled.div`
  background-color: var(--surface-dim);
  height: 13.5rem;
  min-width: 13.5rem;
  position: relative;
  border-radius: var(--radius-round) var(--radius-round) 0 0;

  :before {
    opacity: 0;
    background-color: var(--darken-neutral-hover);
    transition: opacity 200ms;
    content: '';
    border-radius: var(--radius-round) var(--radius-round) 0 0;
    position: absolute;
    inset: 0;
  }

  body[data-mobile='true'] & {
    height: 100%;
    height: 7.5rem;
    width: 7.5rem;
    min-width: unset;
    border-radius: var(--radius-round) 0 0 var(--radius-round);
  }
`;

export const CardBottom = styled.div`
  font-family: var(--font-sans);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-lg);
  color: var(--on-surface-primary);
  flex-grow: 1;

  body[data-mobile='true'] & {
    font-size: 14px;
    padding: var(--spacing-md);
    align-items: start;
  }
`;

export const CardTitle = styled.div`
  font-weight: 700;
  margin-bottom: var(--spacing-sm);
`;

export const CardText = styled.div`
  font-weight: 400;
  text-align: center;

  body[data-mobile='true'] & {
    text-align: left;
  }
`;

export const AnimationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LottieWrapper = styled.div`
  width: 12rem;

  &[data-mobile='true'] {
    width: 4rem;
  }
`;

export const Image = styled.img`
  max-width: 3.75rem;
`;
